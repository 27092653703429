<template>
  <div>
    <div class="controls-panel">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-6 col-md-3 pe-4 ps-3">
              <div class="row">
                <div class="col-3">
                  <button class="btn btn-control p-0"
                          content="Select"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <div class="form-group">
                      <input type="checkbox" name=""
                             class="form-check-input m-0"
                             @click="selectAll(selectAllCheckbox)"
                             v-model="selectAllCheckbox">
                    </div>
                  </button>
                </div>
                <div class="col-3">
                  <button class="btn btn-control"
                          :class="{disabled: editAllow === false}"
                          @click="modals.editExpense = true"
                          content="Edit"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <img src="@/assets/icons/icon-edit.svg" alt="Edit">
                  </button>
                </div>
                <div class="col-3">
                  <button class="btn btn-control"
                          @click="deleteSelected"
                          content="Delete"
                          v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
                    <img src="@/assets/icons/bold-icon delete.svg" alt="Delete">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-end mt-3 mt-md-0">
          <div class="d-flex align-items-center justify-content-end">
            <span class="color-light-grey border-right-light pe-3 me-3 selected-count">
              {{ selectedCount }} selected
            </span>
            <pagination
              class="justify-content-end"
              :from="10"
              :to="20"
              :total="300"
              :page="1"
              :per-page="2" />
          </div>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField">
          </div>
        </div>
      </div>
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table"  v-if="ui.loading === false">
        <table class="table">
          <thead>
          <tr>
            <th></th>
            <th>Bookkeeper</th>
            <th>Agent</th>
            <th>Loan Number</th>
            <th>Amount</th>
            <th>Category</th>
            <th>Date</th>
            <th>Range</th>
            <th>Memo</th>
            <th>Vendor Name</th>
            <th>Status</th>
            <th>Invoice</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(expense, index) in expensePending" :key="index">
            <td>
              <div class="form-group">
                <input type="checkbox" name=""
                       @click="countSelected(expense.checked); expense.checked = !expense.checked"
                       v-model="expense.checked"
                       id="" class="form-check-input m-0" >
              </div>
            </td>
            <td>{{ expense.bookkeeper }}</td>
            <td>Jason Vieira</td>
            <td>N/A</td>
            <td>$50.00</td>
            <td>Utilities (indirect)</td>
            <td>November 12, 2020 00:00</td>
            <td>Nov, 12 2020 - Dec, 12 2020</td>
            <td>Cierra RingCentral</td>
            <td>RingCentral</td>
            <td>
              <div class="status d-flex justify-content-center unverified">
                unverified
              </div>
            </td>
            <td>N/A</td>
            <td>
              <button class="btn btn-control" :name="'expense' + index">
                <img src="@/assets/icons/icon-more.svg" alt="">
              </button>
              <tippy  boundary="window"
                      interactive="true"
                      trigger="click"
                      class="borrower-action"
                      zIndex="1039"
                      animateFill="false"
                      placement="bottom-end"
                      theme="light"
                      :to="'expense' + index">
                <div class="table-menu-block">
                  <ul>
                    <li class="action-label">
                      Actions
                    </li>
                    <li @click="modals.editExpense = true; expenseId = expense.id" class="cursor-pointer">
                      <a href="#">
                        <img src="@/assets/icons/icon-edit-normal.svg" alt="">
                        Edit
                      </a>
                    </li>
                    <li class="cursor-pointer" @click="deleteExpense(expense.id)">
                      <a href="#">
                        <img src="@/assets/icons/icon-delete.svg" alt="">
                        Delete
                      </a>
                    </li>
                  </ul>

                </div>
              </tippy>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :from="10"
        :to="20"
        :total="300"
        :page="1"
        :per-page="2" />

    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination";
export default {
  name: 'ExpensePending',
  components: { Pagination },
  data() {
    return {
      searchField: '',
      selectAllCheckbox: false,
      editAllow: false,
      selectedCount: 0,
      expensePending: [],
      modals: {
        newExpense: false,
        editExpense: false
      },
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.expensePending = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
        this.expensePending.map((expense) => {
          expense.checked = false;
        });
        this.ui.loading = false;
      }, 1000)
    },
    selectAll(val) {
      if (val === false) {
        this.expensePending.map((expense) => {
          expense.checked = true;
          this.selectedCount++;
        })
      } else {
        this.expensePending.map((expense) => {
          expense.checked = false;
          this.selectedCount--;
        })
      }
    },
    countSelected(val) {
      if (val === false) {
        this.selectedCount++;
        this.selectAllCheckbox = true;
        if (this.selectedCount === 1) {
          this.editAllow = true;
        }
      } else {
        this.selectedCount--;
        this.editAllow = false;
        if (this.selectedCount === 0) {
          this.selectAllCheckbox = false;
        }
      }
    },
    deleteSelected() {
      const deleteRequests = this.expensePending.filter((request) => {
        return request.checked === true
      });

      if (deleteRequests.length > 0) {
        this.ui.loading = true;
        setTimeout(() => {
          this.ui.loading = false;
          this.selectAllCheckbox = false;
          this.expensePending = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
          this.editAllow = false;
        }, 1000);
      } else {
        alert('Pls select')
      }
    },
    deleteExpense(id) {
      console.log(id);
      this.ui.loading = true;
      setTimeout(() => {
        this.expensePending = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
        this.expensePending.map((expense) => {
          expense.checked = false;
        });
        this.ui.loading = false;
        this.editAllow = false;
      }, 1000)
    },
    goto(routeName) {
      this.$router.push({name: routeName})
    },
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    page() {
      return this.$route.name
    }
  }
}
</script>
